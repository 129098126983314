<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px">
    <v-row no-gutters class="mx-2" v-if="brandCollection">
      <!-- Picture -->
      <v-col cols="12">
        <v-row justify="start" align="center" no-gutters>
          <h4>{{ $t("collections.specifications.picture.title") }}</h4>

          <div>
            <!-- Edit picture btn -->
            <BtnIconEdit
              v-if="brandCollection.picture && brandCollection.picture.url"
              @click="toggleEditPictureDialog(true)"
            />
            <BtnIconAdd v-else @click="toggleEditPictureDialog(true)" />

            <!-- Delete picture btn -->
            <BtnIconDelete
              v-if="brandCollection.picture && brandCollection.picture.url"
              @click="deleteCollectionPicture(brandCollection.picture.id)"
            />
          </div>
        </v-row>
        <v-img
          v-if="brandCollection.picture"
          :src="brandCollection.picture.URL"
          width="400"
        />
      </v-col>
    </v-row>

    <!-- Picture upload -->
    <ChangePictureDialog
      v-if="showDialog.editPicture"
      :value="showDialog.editPicture"
      :UPDATE_API="`/brand-collections/${brandCollection.id}/picture`"
      @close="(needRefresh) => toggleEditPictureDialog(false, needRefresh)"
    />
  </v-card>
</template>

<script>
export default {
  name: "CollectionSpecificationsTab",
  components: {
    BtnIconDelete: () => import("@/components/Common/Buttons/BtnIconDelete"),
    BtnIconEdit: () => import("@/components/Common/Buttons/BtnIconEdit"),
    BtnIconAdd: () => import("@/components/Common/Buttons/BtnIconAdd"),
    ChangePictureDialog: () =>
      import("@/components/Common/Picture/ChangePictureDialog"),
  },
  props: {
    brandCollection: { type: Object, default: () => null },
  },

  data() {
    return {
      showDialog: {
        editPicture: false,
      },

      isLoading: {
        btnPictureDelete: false,
      },
    };
  },

  methods: {
    toggleEditPictureDialog(val, needRefresh = false) {
      if (needRefresh) this.$emit("refresh");
      this.showDialog.editPicture = val;
    },

    deleteCollectionPicture(pictureId) {
      this.$http
        .delete(
          `/brand-collections/${this.brandCollection.id}/pictures/${pictureId}`,
          {
            headers: { Authorization: "Bearer " + this.$session.get("jwt") },
          }
        )
        .then((res) => {
          this.$emit("refresh");
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoading.btnPictureDelete = false;
        });
    },
  },
};
</script>

<style scoped></style>
